import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['content', 'button', 'input', 'list', 'fromNumberInput', 'toNumberInput', 'innerContent', 'searchField'];

  connect() {
    this.handleClickOutside = this.handleClickOutside.bind(this)
    document.addEventListener('click', this.handleClickOutside)
  }

  disconnect() {
    document.removeEventListener('click', this.handleClickOutside)
  }

  contentTargetConnected() {
    if (this.contentTarget.offsetHeight > this.innerContentTarget.offsetHeight) {
      this.searchFieldTarget.classList.add('tw-hidden')
    }
  }

  inputTargetConnected() {
    this.inputTarget.focus()
  }

  rangeSearch(e) {
    e.preventDefault()
    if (this.fromNumberInputTarget.value && this.toNumberInputTarget.value) {
      this.updateSearchFilter({
        value: { from: this.fromNumberInputTarget.value, to: this.toNumberInputTarget.value },
        fieldName: this.fromNumberInputTarget.dataset['fieldNameValue'], fieldType: 'number' })
    }
  }

  dateRangeSearch(e) {
    e.preventDefault()
    if (this.fromNumberInputTarget.value && this.toNumberInputTarget.value) {
      this.updateSearchFilter({
        value: { from: this.fromNumberInputTarget.value, to: this.toNumberInputTarget.value },
        fieldName: this.fromNumberInputTarget.dataset['fieldNameValue'], fieldType: 'date' })
    }
  }

  updateCategoryTree(e) {
    e.preventDefault()
  }

  categorySearch(e) {
    e.preventDefault()
    const checkedCheckboxes = this.listTarget.querySelectorAll('input[type="checkbox"]:checked')
    const ids = new Set(Array.from(checkedCheckboxes).map((checkbox) => checkbox.value))
    this.updateSearchFilter({ value: Array.from(ids), fieldName: 'category', fieldType: 'category' })
  }

  listTargetConnected() {
    if (!this.hasInputTarget) {
      return
    }

    const frontendSearch = this.inputTarget.dataset['triggerFrontendSearch']
    if (frontendSearch) {
      const searchTerm = this.inputTarget.value.toLowerCase()
      this.frontendSearch(searchTerm)
    }
  }

  updateTextFilter(e) {
    e.stopPropagation()
    e.preventDefault()
    const searchTerm = this.inputTarget.value
    const fieldName = e.target.dataset['fieldNameValue']
    const type = e.target.dataset['typeValue']
    if (searchTerm) {
      this.throttledSearch({ value: searchTerm, fieldName: fieldName, fieldType: type })
    }
  }

  preventSubmit(e) {
    if (event.key === 'Enter') {
      event.preventDefault()
    }
  }

  updateSelectFilter(e) {
    const value = e.target.dataset['fieldValue']
    const fieldName = e.target.dataset['fieldNameValue']
    const type = e.target.dataset['typeValue']
    this.throttledSearch({ value: value, fieldName: fieldName, fieldType: type })
  }

  searchFrontendContent() {
    const searchTerm = this.inputTarget.value.toLowerCase()
    this.frontendSearch(searchTerm)
  }

  toggleCheckbox(e) {
    const value = e.target.dataset['fieldValue']
    const fieldName = e.target.dataset['fieldNameValue']
    const type = e.target.dataset['typeValue']
    this.updateSearchFilter({ value: value, fieldName: fieldName, fieldType: type })
  }

  multiSearch(e) {
    e.preventDefault()

    const fieldName = e.target.dataset['fieldNameValue']
    const type = e.target.dataset['typeValue']

    if (this.hasContentTarget) {
      const checkboxes = this.contentTarget.querySelectorAll('input[type="checkbox"]:checked')
      const values = Array.from(checkboxes).map((checkbox) => checkbox.dataset['fieldValue'])
      this.updateSearchFilter({ value: values, fieldName: fieldName, fieldType: type })
    }
  }

  frontendSearch(value) {
    this.listTarget.querySelectorAll('.dropdown-item').forEach((item) => {
      const text = item.querySelector('label').textContent.toLowerCase()
      const isVisible = text.includes(value)
      item.style.display = isVisible ? 'block' : 'none'
    })
  }

  throttledSearch(options) {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    this.timeout = setTimeout(() => {
      this.updateSearchFilter(options)
    }, 3000)
  }

  updateSearchFilter(options) {
    const event = new CustomEvent('column-search', {
      detail: options,
    })
    window.dispatchEvent(event)
  }

  updateCalendarFilter(e) {
    const options = e.detail
    if (options[0]) {
      this.fromNumberInputTarget.value = options[0].toLocaleDateString()
    }
    if (e.detail[1]) {
      this.toNumberInputTarget.value = e.detail[1].toLocaleDateString()
    }
    console.log(e)
  }

  handleClickOutside(e) {
    if (e.target.classList.contains('dropdown-toggle') ||
      e.target.closest('.dropdown-toggle')) {
      e.preventDefault()
    }
    if (!this.element.contains(e.target)) {
      this.element.remove()
      const elements = document.querySelectorAll('.indicate_active_filter')
      elements.forEach((element) => {
        element.classList.remove('tw-bg-blue-50')
      })
    }
  }

  updateFilterContent(e) {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    this.timeout = setTimeout(() => {
      const query = e.target.value
      const url = e.target.dataset.url + '&query=' + query
      console.log(url)
      fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'text/vnd.turbo-stream.html',
          'X-CSRF-Token': document.querySelectorAll('[name="csrf-token"]')[0].content,
        },
      })
        .then((r) => r.text())
        .then((html) => Turbo.renderStreamMessage(html))
    }, 1000)
  }
}
